import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppContext from '../utils/appContext';
import Icon from '../components/icons';
import ChevRight from '../components/chevright';

const Navigation = () => {
  const context = useContext(AppContext);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );

  const [navigation, setNavigation] = useState([
    { name: 'Dashboard', href: '/', icon: 'home' },
  ]);
  const [activePage, setActivePage] = useState('');

  useEffect(() => {
    setActivePage(location.pathname.split('/')[1]);
  }, [location]);
  useEffect(() => {
    console.log('activePage', activePage);
  }, [activePage]);
  const roles = JSON.parse(user.groups || '[]');

  useEffect(() => {
    const newNavigation = [{ name: 'Home', href: '/', icon: 'home' }];

    if (roles.includes(0) || roles.includes(1)) {
      //push two items to the array

      newNavigation.push(
        {
          name: 'Users',
          href: '/users',
          icon: 'members',
        }
        // { name: 'Contacts', href: '/contacts', icon: 'contact' },
        // { name: 'Mail', href: '/email', icon: 'email' }
      );
    }

    if (roles.includes(0)) {
      newNavigation.push({ name: 'Bin', href: '/bin', icon: 'bin' });
    }

    setNavigation(newNavigation);
    setShow(true);
  }, []);

  return (
    show && (
      <div className="grid content-between min-h-screen py-2 fade-in md:py-10">
        <div className="top">
          <div className="px-3 mb-5 lg:px-10 md:px-5 lg:mb-10 xl:mb-10">
            <img
              src="/Suzy-logo.svg"
              alt="Suzy"
              className="inline-block max-h-7 md:max-h-16"
            />
          </div>

          <nav>
            <ul role="list" className="grid ">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a
                    onClick={() => navigate(item.href)}
                    className={`${
                      '/' + activePage === item.href
                        ? 'bg-[#fffefe] '
                        : ' hover:bg-[#fffefe]'
                    }
                  cursor-pointer transition-all group flex justify-center lg:justify-between lg:px-10  py-3 px-3 md:px-5 text-sm leading-6 items-center`}
                  >
                    <div className="flex gap-x-3">
                      <Icon iconName={item.icon} />
                      <span className="hidden lg:block">{item.name}</span>
                    </div>

                    <span className="hidden lg:block mt-[3px]">
                      <ChevRight />
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    )
  );
};
export default Navigation;
